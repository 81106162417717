import * as React from "react";
import {
  Button, Divider, Grid, Header
} from "semantic-ui-react";
import { connect } from "react-redux";
import { navigateTo } from "../../helpers/router";
import queryString from "query-string";
import "../../css/paymentconfirmation.css"

interface Props {
  router: any
}
interface State {
  nrb: string
  price: string
}

export default class TransferConfirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nrb: null,
      price: null
    }
      ;
  }

  componentDidMount() {
    let params = this.props.router.location.search;
    if (params) {
      params = queryString.parse(params);
      if (params.nrb && params.price) {
        this.setState({ nrb: params.nrb, price: params.price})
      }
    }
    this.layerPush("eec.purchase");
  }

  layerPush(event) {
    if (window.dataLayer === undefined) {
      console.error('missing datalayer')
    } else {
      window.dataLayer.push({
        'event': event
      })
      console.log("order purchase");
    }
  }

  displayContent() {
    return (
      <div className="container positive">
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Header as='h1' style={{ color: "#FFFFFF" }}>opłać swoje zamówienie</Header>
              <p>Aby sfinalizować transakcję posimy o wpłatę kwoty: {this.state.price} zł<br />
                na rachunek bankowy numer: {this.state.nrb}</p>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Button id="btn-back" onClick={() => navigateTo("/")}>Powrót</Button>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  render() {
    return (

      <div style={{ height: "calc(100vh - 4px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {this.displayContent()}
      </div>

    );
  }
}